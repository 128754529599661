<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Company information</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Update information of this company.</span>
      </div>
    </div>

    <div class="card-body">
      <!-- begin::Form -->
      <form class="form">
        <specifications-form
          ref="specificationsForm"

          :document="document"

          :action="($auth.hasPermission('companies.update') ? 'EDIT' : 'VIEW')"
          :disabled="isLoadingForm"
        />

        <div v-if="$auth.hasPermission('companies.update')" class="d-flex justify-content-between border-top pt-10">
          <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4 ml-auto" @click.prevent="submitForm">
            Save
          </button>
        </div>
      </form>
      <!-- end::Form -->
    </div>
  </div>
</template>

<script>
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';
import { validations, mutations as mutationsLib } from '@vedicium/core-vue';
import specificationsForm from '@/components/pages/companies/components/specifications.form.vue';

export default {
  props: {
    company: Company,
    default: null,
  },
  components: {
    specificationsForm,
  },
  data () {
    return {
      isLoadingForm: false,

      document: (this.company ? this.company.clone() : null),
    };
  },

  methods: {
    async validate () {
      const validationArray = [this.$refs.specificationsForm.$v];

      await validations.validateArray(validationArray);
    },
    async submitForm () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        const mutations = mutationsLib.create(['name', 'location.place_id', 'location.description'], this.company.formatBody('update'), this.document.formatBody('update'));
        if (mutations.length > 0) {
          const response = await Company.update(this.company._meta.guid, mutations);
          this.company.merge(response);
          this.$set(this, 'document', this.company.clone());
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }
    },
  },
};
</script>
